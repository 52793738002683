import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../../../template/Layout"
import Head from "../../../head";
import { BlogPaginator } from "../../../components/blog/common/paginator";
import { WPFrontMatter } from "../../../types/WPFrontMatter";
import Articles from "../../../components/blog/Articles";
import { formatWPSlug } from "../../../utlis/StringUtils"
import { OWNEDMEDIA_WORDPRESS_URL } from "../../../AppConst";
import { RedirectToErrorPage } from "../../../utlis/404Util";

const fetchTagPosts = async (apiTag, page, setPosts, setTotalPages, setIsLoading, setHasError) => {
  setIsLoading(true);

  fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/tags?slug=${apiTag}`)
  .then(response => {return response.json()})
  .then(tagData => {
    if(!tagData.length) return RedirectToErrorPage(window);

    fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/posts?_embed&tags=${tagData[0].id}&per_page=12&page=${page}`)
    .then(response => {
      setTotalPages(Number(response.headers.get("X-WP-TotalPages")));
      return response.json();
    })
    .then(postsData => {
      if(!postsData.length) return RedirectToErrorPage(window);

      const posts: WPFrontMatter[] = [];

      postsData.map((data, i) => {
        const postData: WPFrontMatter = {
          title: data.title.rendered,
          date: data.date,
          category: data._embedded["wp:term"][0][0].slug,
          description: "",
          author: data._embedded.author[0].name,
          thumbnail: data._embedded["wp:featuredmedia"][0].source_url,
          slug: `${data._embedded.author[0].slug}/${data.slug}`
        }
        posts.push(postData);
      })

      setPosts(posts);
      setIsLoading(false);
    })
    .catch((error) => {
      setHasError(true);
    });
  })
  .catch((error) => {
    setHasError(true);
  });
};

/**
 *@ Element & Styles
 */
const baseBreadcrumbsData: { text: string; path: string }[] = [
  { text: `ブログ`, path: `blog` },
]

export default function TagBlogs({params, data}) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [posts, setPosts] = useState<WPFrontMatter[] | null>(null);
  const [totalPages, setTotalPages] = useState(0);
  const tag = params.tag;

  if(!tag) return null;

  // WPのRestApiで使用
  const apiTag = formatWPSlug(tag);
  const pageNumber = Number(params.page)

  useEffect(() => {
    fetchTagPosts(apiTag, pageNumber, setPosts, setTotalPages, setIsLoading, setHasError)
  }, [params.page])

  const breadcrumbsData = [
    ...baseBreadcrumbsData,
    ...[{ text: tag.toUpperCase(), path: `/tags/${tag}/` }]
  ]
  const title = `${tag}の記事一覧 | ネクストライブ株式会社`
  const description = `ブログの${tag}の記事一覧ページです。テクノロジー、クリエイティブ、プログラミング、ビジネス、ワークスタイル、ライフスタイル、エンタメ、お知らせのブログ記事の一覧を掲載しています。`

  return (
    <Layout sidebar={true} breadcrumbsData={breadcrumbsData}>
      <Head
        title={title}
        description={description}
      />
      <Articles pageTitle={`${tag}の記事一覧`} posts={posts} members={data.site.siteMetadata.member} isLoading={isLoading} hasError={hasError} />
      <BlogPaginator
        basePath={`/tags/${tag}`}
        prev={pageNumber > 2 ? `/${tag}/${pageNumber - 1}` : `/tags/${tag}`}
        next={pageNumber < totalPages ? `/${tag}/` + (pageNumber + 1) : ''}
        numberOfPages={totalPages}
        current={pageNumber}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        member {
          name
          filename
        }
      }
    }
  }
`
